import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'ag-pie-chart',
  templateUrl: './pie-chart.component.html'
})

export class PieChartComponent implements AfterViewInit {

  @Output() selectedPhase = new EventEmitter<string>();
 

  @ViewChild('pieChart') chartReference: ElementRef | any;
  chart: Chart | any;

  @Input() chartType: "pie" | "doughnut" = "pie";
  @Input() chartLegendfConfig: any = {
    position: 'bottom',
  };
  @Input() chartDataSet: any;
  @Input() chartLabels: any;
  @Input() isChartHalfDoughnut: boolean = false;

  constructor() {
  }

  ngAfterViewInit() {

    const graphClickEvent = (event: any, item: any) => {

      //For phase distribution graph only
      if (event.chart.id == 0 && item.length > 0) {
        var index = item[0].index;
        this.selectedPhase.emit(index + 1);
      }
    }

    this.chart = new Chart(this.chartReference.nativeElement, {
      type: this.chartType,
      data: {
        datasets: this.chartDataSet,
        labels: this.chartLabels
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        circumference: this.isChartHalfDoughnut ? 180 : 360,
        rotation: this.isChartHalfDoughnut ? -90 : 0,
        plugins: {
          legend: this.chartLegendfConfig,
        },
        onClick: graphClickEvent,
      },
    });
  }
}
