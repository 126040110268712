import { PlatformLocation } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { AppConsts } from "./shared/AppConsts";
import { AppSessionService } from "./services/session/app-session-service";

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(
    private _injector: Injector,
    private _platformLocation: PlatformLocation,
    private _httpClient: HttpClient
  ) { }

  init(): () => Promise<boolean> {
    return () => {
      return new Promise((resolve) => {
        AppConsts.apiBaseUrl = "http:localhost:54125";
        AppConsts.appA360BaseUrl = "http:localhost:55000";
        AppConsts.app3eWrapperUrl = "http://localhost:5099";
        resolve(true);

        //AppConsts.appBaseHref = this.getBaseHref();
        //const appBaseUrl = this.getDocumentOrigin() + AppConsts.appBaseHref;
        //AppConsts.appBaseUrl = appBaseUrl;
        //const appSessionService = this._injector.get(AppSessionService);
        //appSessionService.init().then(
        //  (result) => {
        //    this.getApplicationConfig(appBaseUrl, () => {
        //      resolve(true);
        //    });
        //  }
        //)
        // this.getToken(appBaseUrl, () => {
        //     this.getApplicationConfig(appBaseUrl, () => {
        //         resolve(true);
        //     });
        // });
      });
    }
  }

  private getBaseHref(): string {
    const baseUrl = this._platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
      return baseUrl;
    }

    return '/';
  }

  private getDocumentOrigin(): string {
    if (!document.location.origin) {
      const port = document.location.port ? ':' + document.location.port : '';
      return (
        document.location.protocol + '//' + document.location.hostname + port
      );
    }

    return document.location.origin;
  }

  private getToken(appRootUrl: string, callback: () => void) {
    this._httpClient
      .get<any>(`${appRootUrl}account/token`)
      .subscribe((response) => {
        callback();
      })
  }

  private getApplicationConfig(appRootUrl: string, callback: () => void) {
    this._httpClient
      .get<any>(`${appRootUrl}api/config`)
      .subscribe((response) => {
        AppConsts.apiBaseUrl = response.baseApiUrl;
        callback();
      })
  }
}
