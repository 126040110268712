export const environment = {
  production: true,
  applicationBaseUrl: "https://sb.clientportal.alliantgroup.com",
  igniteBaseUrl: "https://scus-cg-ignite-app-svc-dev.azurewebsites.net",
  renewalUrl: "https://testalliant.wpengine.com/contract-renewal-test/?fname={firstName}&lname={lastName}&phone={phoneNumber}&email={email}&company={formatted_company}&calendaryear={targetyear}&utm_source=client-portal&utm_campaign=client-retention&utm_medium=hyperlink&utm_content=renew-button&utm_term={currentyear}",
  "ApiSettings": {
    "ClientPortalApi": {
      "ApiName": "ClientPortalApi",
      "BaseUrl": "https://sb.clientportalapi.alliantgroup.com/api/",
      "Token": "Basic ZGV2X01TX0FQSV9Vc2VyOmRldl9NU19BUElfUHdk",
      "AuthToken": "Basic 589F82163DB04954ACFC81B64DAD1DDCBE2460F0DB18478FA967A9BF268746559D660B2A43C94B19944517547354D203"

    },
    "BlobMicroServiceApp": {
      "ApiName": "CP.BlobService",
      "BaseUrl": "https://blobapi.sb.clientportal.alliantgroup.com",
      "Token": ""
    }
  }
};
