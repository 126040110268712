import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { distinctUntilChanged, filter } from "rxjs";
import { IBreadCrumb } from "./breadcrumb.interface";
import { BreadcrumbService } from "./breadcrumb.service";

@Component({
    selector: 'ag-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    public breadcrumbs: IBreadCrumb[];

    private routerEventSubscription: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService
    ) {

    }

    ngOnInit(): void {
        this.routerEventSubscription = this.router.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe(() => {            
            this.buildBreadCrmb();
        });

        this.buildBreadCrmb();
    }

    ngOnDestroy(): void {        
        this.routerEventSubscription?.unsubscribe();
    }

    buildBreadCrmb(): void {
        this.breadcrumbs = this.breadcrumbService.buildBreadCrmb(this.activatedRoute.root);
    }
}
