<div class="row mb-10" *ngIf="showGridOptions && viewsEnabled">
  <div class="col-md-4">
    <ng-select [items]="viewList" bindLabel="name" bindValue="viewId" [clearable]="false" [searchable]="false" [(ngModel)]="selectedView" (change)="viewListChange($event)" style="width: 250px;">
      <ng-template ng-label-tmp let-item="item">
        <b>{{item.name}} <span *ngIf="item.template">*</span></b>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{item.name}} <span *ngIf="item.template"><b>*</b></span>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-md-8">
    <div style="display: flex; justify-content: flex-end;">
      <button type="button" class="btn btn-transparent mr-3">
        <span class="badge" [ngClass]="{'bg-warning text-dark': saveSettingMessage==='saving', 'bg-success': saveSettingMessage==='saved' }">{{saveSettingMessage}}</span>
      </button>

      <ng-template [ngTemplateOutlet]="viewOptionsPl.templateRef || null"></ng-template>

      <button type="button" class="btn btn-outline-primary mr-3" (click)="refreshGrid()">
        <span class="fas fa-refresh"></span>
      </button>

      <button type="button" class="btn btn-outline-primary mr-3" (click)="doSaveAsViewFilter()" *ngIf="getSelectedView()?.template === true">
        Save Filter As
      </button>

      <button type="button" class="btn btn-outline-primary mr-3" (click)="doSaveViewFilter()" *ngIf="getSelectedView()?.template === false">
        Save Filter
      </button>

      <button type="button" class="btn btn-outline-primary mr-3" (click)="doResetView()" *ngIf="getSelectedView()?.template === true">
        Reset View
      </button>

      <div class="dropdown">
        <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="fas fa-gear"></span>
        </button>
        <div class="dropdown-menu grid-option-dd" aria-labelledby="dropdownMenuButton1">
          <button class="dropdown-item" type="button" (click)="onNewViewListBtnClicked()"><span class="fa fa-plus"></span> New</button>
          <button class="dropdown-item" type="button" (click)="onCloneViewListBtnClicked()"><span class="fa fa-copy"></span> Clone</button>
          <button class="dropdown-item" type="button" (click)="onRenameViewListBtnClicked()" [disabled]="getSelectedView()?.template"><span class="fa fa-i-cursor"></span> Rename</button>
          <button class="dropdown-item btn-delete" type="button" (click)="onDeleteViewListBtnClicked()" [disabled]="getSelectedView()?.template"><span class="fa fa-trash"></span> Delete</button>
          <button class="dropdown-item" type="button" (click)="onSetDefaultViewListBtnClicked()" *ngIf="!getSelectedView()?.default"><span class="fa fa-circle-check"></span> Set as default</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <ag-grid-angular class="ag-grid ag-theme-material" style="width:100%; min-height: 30vh; height:30vh;" [ngStyle]="gridStyles" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [autoGroupColumnDef]="autoGroupColumnDef" [rowModelType]="rowModelType" [serverSideInfiniteScroll]="isServerSideInfiniteScroll" [pagination]="pagination" [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" [animateRows]="true" [sideBar]="sideBar" [rowSelection]="rowSelection" [rowMultiSelectWithClick]="false" [rowGroupPanelShow]="rowGroupPanelShow" [groupDisplayType]="groupDisplayType" [alwaysMultiSort]="alwaysMultiSort" [statusBar]="statusBar" (gridReady)="onGridReady($event)" (columnMoved)="onColumnMoved($event)" (columnResized)="onColumnResized($event)" (columnPinned)="onColumnPinned($event)" (columnVisible)="onColumnVisible($event)" (selectionChanged)="onSelectionChanged($event)" (columnRowGroupChanged)="onColumnRowGroupChanged($event)" (sortChanged)="onSortChanged($event)" [suppressAggFuncInHeader]="true" [domLayout]="domLayout" [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [suppressRowClickSelection]="suppressRowClickSelection" [suppressCellSelection]="suppressCellSelection">
    </ag-grid-angular>
  </div>
</div>

<ag-modal #newViewListModal="agModal" title="Create a new view list" [showCloseButton]="true" [isAutoClose]="false" size="sm" (closed)="viewListModalClosed()" *ngIf="showGridOptions && viewsEnabled">
  <ng-template agModalContent>
    <input class="form-control mb-10" [(ngModel)]="listViewModalInput" type="text" placeholder="Enter view name" [autofocus] />
  </ng-template>
  <ng-template agModalFooter>
    <button type="button" class="btn btn-primary" (click)="doCreateNewViewList()">Create</button>
    <button type="button" class="btn btn-outline-primary" (click)="newViewListModal.close()">Cancel</button>
  </ng-template>
</ag-modal>

<ag-modal #saveAsViewListModal="agModal" title="Save selected list to a new view list" [showCloseButton]="true" [isAutoClose]="false" size="sm" (closed)="viewListModalClosed()" *ngIf="showGridOptions && viewsEnabled">
  <ng-template agModalContent>
    <input class="form-control mb-10" [(ngModel)]="listViewModalInput" type="text" placeholder="Enter view name" [autofocus] />
  </ng-template>
  <ng-template agModalFooter>
    <button type="button" class="btn btn-primary" (click)="doSaveAsViewList()">Create</button>
    <button type="button" class="btn btn-outline-primary" (click)="saveAsViewListModal.close()">Cancel</button>
  </ng-template>
</ag-modal>

<ag-modal #cloneViewListModal="agModal" title="Clone view" [showCloseButton]="true" [isAutoClose]="false" size="sm" (closed)="viewListModalClosed()" *ngIf="showGridOptions && viewsEnabled">
  <ng-template agModalContent>
    <input class="form-control mb-10" [(ngModel)]="listViewModalInput" type="text" placeholder="Enter view name" [autofocus] />
  </ng-template>
  <ng-template agModalFooter>
    <button type="button" class="btn btn-primary" (click)="doCloneViewList()">Clone</button>
    <button type="button" class="btn btn-outline-primary" (click)="cloneViewListModal.close()">Cancel</button>
  </ng-template>
</ag-modal>

<ag-modal #renameViewListModal="agModal" title="Rename view" [showCloseButton]="true" [isAutoClose]="false" size="sm" (closed)="viewListModalClosed()" *ngIf="showGridOptions && viewsEnabled">
  <ng-template agModalContent>
    <input class="form-control mb-10" [(ngModel)]="listViewModalInput" type="text" placeholder="Enter view name" />
  </ng-template>
  <ng-template agModalFooter>
    <button type="button" class="btn btn-primary" (click)="doRenameViewList()">Rename</button>
    <button type="button" class="btn btn-outline-primary" (click)="renameViewListModal.close()">Cancel</button>
  </ng-template>
</ag-modal>

<ag-modal #deleteViewListModal="agModal" title="Delete selected view List" [showCloseButton]="true" [isAutoClose]="false" size="sm" (closed)="viewListModalClosed()" *ngIf="showGridOptions && viewsEnabled">
  <ng-template agModalContent>
    <p>Do you want to delete current selected List?</p>
  </ng-template>
  <ng-template agModalFooter>
    <button type="button" class="btn btn-primary" (click)="doDeleteViewList()">Delete</button>
    <button type="button" class="btn btn-outline-primary" (click)="deleteViewListModal.close()">Cancel</button>
  </ng-template>
</ag-modal>
