import { AfterContentChecked, AfterViewInit, Component, ContentChildren, Directive, ElementRef, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbAccordion } from "@ng-bootstrap/ng-bootstrap/accordion/accordion";

@Directive({ selector: 'ng-template[agPanelHeader]' })
export class AgPanelHeader {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agPanelContent]' })
export class AgPanelContent {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agPanelFooter]' })
export class AgPanelFooter {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agPanelTitle]' })
export class AgPanelTitle {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
    selector: 'ag-accordion',
    exportAs: 'agAccordion',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './accordian.component.html',
    styleUrls: ['./accordian.component.scss']
})
export class AgAccordion implements AfterContentChecked, AfterViewInit {
    @ViewChild('ngbAcc') ngbAccordion: NgbAccordion;

    @Input() title: string;
    @Input() editable: boolean;
    @Input() isExpand: boolean;
    @Input() isTransparent: boolean;
    @Input() titleBold: boolean = false;
    @Output() isEdit: boolean;

    @Output() isEditMode = new EventEmitter<boolean>();

    headerPl: AgPanelHeader;
    contentPl: AgPanelContent;
    footerPl: AgPanelFooter;
    titlePl: AgPanelTitle;

    panelId: string = 'ngb-panel-0';

    @ContentChildren(AgPanelHeader, { descendants: false }) headerPls: QueryList<AgPanelHeader>;
    @ContentChildren(AgPanelContent, { descendants: false }) contentPls: QueryList<AgPanelContent>;
    @ContentChildren(AgPanelFooter, { descendants: false }) footerPls: QueryList<AgPanelFooter>;
    @ContentChildren(AgPanelTitle, { descendants: false }) titlePls: QueryList<AgPanelTitle>;

    ngAfterContentChecked(): void {
        this.headerPl = this.headerPls.first;
        this.contentPl = this.contentPls.first;
        this.footerPl = this.footerPls.first;
        this.titlePl = this.titlePls.first;
    }

    ngAfterViewInit(): void {
        this.panelId = this.getPanelId();

        if (this.isExpand) {
            this.expand();
        }
    }

    expand(): void {
        this.ngbAccordion.expand(this.panelId);
    }

    collapse(): void {
        this.ngbAccordion.collapse(this.panelId);
    }

    showActions(): void {
        this.isEdit = true;
        this.isEditMode.emit(this.isEdit);

        if (this.isEdit && !this.ngbAccordion.isExpanded(this.panelId)) {
            this.expand();
        }
    }

    hideActions(): void {
        this.isEdit = false;
        this.isEditMode.emit(this.isEdit);
    }

    getPanelId(): string {
        return this.ngbAccordion.panels.first.id;
    }
}
