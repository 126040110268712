<div class="p-2 d-flex flex-column h-100">
  <div class="header">
      <img class="header-icon" src="/assets/media/icons/{{image}}.svg">
      <span class="header-title">{{heading}}</span>
      <!-- <div class="display-flex">
        <ng-template [ngTemplateOutlet]="linkPl?.templateRef || null"></ng-template>
      </div> -->
  </div>
  <div class="content">
    <ng-template [ngTemplateOutlet]="contentPl.templateRef || null"></ng-template>
  </div>
</div>
