import { Component, ContentChildren, Directive, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewEncapsulation } from "@angular/core";

@Directive({ selector: 'ng-template[agOverlayHeader]' })
export class AgOverlayHeader {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agOverlayContent]' })
export class AgOverlayContent {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'ag-overlay',
  exportAs: 'agSideOverlay',
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': '(overlayClass ? " " + overlayClass : "")'
  },
  template: `<div class="side-overlay" [style.width.px]="width" [ngClass]="{'side-overlay-on': !isCollapsed}">
              <div class="modal-header">
                <h4> <ng-template [ngTemplateOutlet]="headerPl.templateRef || null"></ng-template> </h4>
                <button type="button" class="btn-close" aria-label="Close" (click)="toggle()"></button>
              </div>
              <div class="modal-body p-0">
                <ng-template [ngTemplateOutlet]="contentPl.templateRef || null"></ng-template>
              </div>
            </div>`
})
export class SideOverlayComponent implements OnInit {

  @Input() isCollapsed: boolean = false;
  @Input() width: number = 500;
  @Input() overlayClass: string = '';

  @Output() collapsed = new EventEmitter<boolean>();

  headerPl: AgOverlayHeader;
  contentPl: AgOverlayContent;

  @ContentChildren(AgOverlayHeader, { descendants: false }) headerPls: QueryList<AgOverlayHeader>;
  @ContentChildren(AgOverlayContent, { descendants: false }) contentPls: QueryList<AgOverlayContent>;

  ngAfterContentChecked(): void {
    this.headerPl = this.headerPls.first;
    this.contentPl = this.contentPls.first;
  }

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.next(this.isCollapsed);
  }

}
