import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConsts } from "src/app/shared/AppConsts";

@Injectable({
  providedIn: 'root'
})
export class AppSessionService {
  private _user: UserLoginInfoDto;
  private _token: string;

  constructor(
    private _httpClient: HttpClient
  ) { }

  get user(): UserLoginInfoDto {
    return this._user;
  }

  get token(): string {
    return this._token;
  }

  getShownLoginName(): string | undefined {
    return this.user?.firstName + ' ' + this.user?.lastName;
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._httpClient
        .get<any>(`${AppConsts.appBaseUrl}account/currentsession`)
        .subscribe((result) => {
          this._user = result.currentUser;
          this._token = result.token;
          resolve(true);
        })
    })
  }
}

export interface IUserLoginInfoDto {
  id: string;
  name: string | undefined;
  username: string | undefined;
  email: string | undefined;
}

export class UserLoginInfoDto {
  id: string;
  name: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;

  constructor(data?: IUserLoginInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.name = _data["name"];
      this.username = _data["username"];
      this.email = _data["email"];
    }
  }

  static fromJS(data: any): UserLoginInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserLoginInfoDto();
    result.init(data);
    return result;
  }
}
