import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padleft'
})
export class PadLeftPipe implements PipeTransform {

  transform(value: string | number, padLength: number, padCharacter: string): string {
    return (padCharacter.repeat(padLength) + value).slice(-padLength);
  }

}
