import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NoteDialogData, UploadWindowState, TriggerUploadActionParams } from '@interfaces/document.interface';
import { UploadWindowSize } from '@enums/document.enum';
import { FileHierarchyNode } from 'src/app/modules/document/models/file-hierarchy-node';

@Injectable({
  providedIn: 'root'
})
export class DocumentUiService {

  private _uploadWindowState$ = new BehaviorSubject<UploadWindowState>({ isVisible: false, size: UploadWindowSize.Minimized });
  private _uploaderDropZoneElement$ = new Subject<HTMLElement>();
  private _uploaderDirectoryUpload$ = new BehaviorSubject<boolean>(false);
  private _uploaderActionCompleted$ = new Subject<any>();
  private _selectedDocumentRequestId$ = new Subject<number>();
  private _uploadActionTrigger$ = new Subject<TriggerUploadActionParams>();
  private _folderNodeSelected$ = new Subject<FileHierarchyNode | undefined>();
  private _noteDialogData$ = new Subject<NoteDialogData>();
  private _openNoteDialog$ = new Subject<boolean>();
  private _documentRequestItemSelected$ = new Subject<number>();
  
  constructor() { }

  //Upload Window State
  setUploadWindowState(state: UploadWindowState): void {
    this._uploadWindowState$.next(state);
  }

  setUploadWindowVisible(isVisible: boolean): void {
    let state = this._uploadWindowState$.getValue();
    state.isVisible = isVisible;

    this._uploadWindowState$.next(state);
  }

  setUploadWindowSize(size: UploadWindowSize): void {
    let state = this._uploadWindowState$.getValue();
    state.size = size;

    this._uploadWindowState$.next(state);
  }

  getUploadWindowState(): Observable<UploadWindowState> {
    return this._uploadWindowState$.asObservable();
  }

  //Uploader Drop Zone Element
  setUploaderDropZoneElement(element: HTMLElement): void {
    this._uploaderDropZoneElement$.next(element);
  }

  getUploaderDropZoneElement(): Observable<HTMLElement> {
    return this._uploaderDropZoneElement$.asObservable();
  }

  //Uploader Directory Upload Flag
  setUploaderDirectoryUpload(isDirectoryUpload: boolean): void {
    this._uploaderDirectoryUpload$.next(isDirectoryUpload);
  }

  getUploaderDirectoryUpload(): Observable<boolean> {
    return this._uploaderDirectoryUpload$.asObservable();
  }

  //Selected Document Request Item Id
  setSelectedDocumentRequestItemId(documentRequestItemId: number): void {
    this._selectedDocumentRequestId$.next(documentRequestItemId);
  }

  getSelectedDocumentRequestId(): Observable<number> {
    return this._selectedDocumentRequestId$.asObservable();
  }

  //Upload Action Trigger
  triggerUploadAction(params: TriggerUploadActionParams): void {
    this._uploadActionTrigger$.next(params);
  }

  onUploadActionTrigger(): Observable<TriggerUploadActionParams> {
    return this._uploadActionTrigger$.asObservable();
  }

  //Select Folder Node
  selectFolderNode(node: FileHierarchyNode | undefined): void {
    this._folderNodeSelected$.next(node);
  }

  onFolderNodeSelected(): Observable<FileHierarchyNode | undefined> {
    return this._folderNodeSelected$.asObservable();
  }

  //Note Dialog
  setNoteDialogData(data: NoteDialogData): void {
    this._noteDialogData$.next(data);
  }

  getNoteDialogData(): Observable<NoteDialogData> {
    return this._noteDialogData$.asObservable();
  }

  openNoteDialog(isReadOnly: boolean): void {
    this._openNoteDialog$.next(isReadOnly);
  }

  onNoteDialogOpened(): Observable<boolean> {
    return this._openNoteDialog$.asObservable();
  }

  //View Document Request Item
  selectDocumentRequestItem(documentRequestItemId: number): void {
    this._documentRequestItemSelected$.next(documentRequestItemId);
  }

  onDocumentRequestItemSelected(): Observable<number> {
    return this._documentRequestItemSelected$.asObservable();
  }

  //Uploader Action Completed
  triggerUploaderActionComplete(event: any): void {
    this._uploaderActionCompleted$.next(event);
  }

  onUploaderActionComplete(): Observable<any> {
    return this._uploaderActionCompleted$.asObservable();
  }
}
