export class AppConsts {
  static appTitle: string = 'Client Portal';
  static appBaseUrl: string;
  static apiBaseUrl: string;
  static appBaseHref: string;
  static appA360BaseUrl: string;
  static app3eWrapperUrl: string;
  static ERCServiceLine: string = "Employee Retention Credit";
  static Energy179DServiceline: string = "179D";
  static ERCServiceLineDBName: string = "ERC";
  static renewalLink: string = "https://www.alliantgroup.com/contract-renewal/?fname={firstName}&lname={lastName}&phone={phoneNumber}&email={email}&company={formatted_company}&calendaryear={targetyear}&utm_source=client-portal&utm_campaign=client-retention&utm_medium=hyperlink&utm_content=renew-button&utm_term={currentyear}";
  static renewalAction: string = 'renewal';
}
