import { Component, Input } from '@angular/core';

@Component({
  selector: 'no-data-placeholder',
  templateUrl: './no-data-placeholder.component.html',
  styleUrls: ['./no-data-placeholder.component.scss']
})
export class NoDataPlaceholderComponent {

  @Input() text: string = "";
  @Input() imageUrl: string = "";
  
}
