import { AfterContentChecked, AfterViewInit, Component, ContentChildren, Directive, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Directive({ selector: 'ng-template[agModalHeader]' })
export class AgModalHeader {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agModalContent]' })
export class AgModalContent {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agModalFooter]' })
export class AgModalFooter {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agModalTitle]' })
export class AgModalTitle {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'ag-modal',
  exportAs: 'agModal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ag-modal.component.html',
})
export class AgModal implements AfterContentChecked, AfterViewInit {

  constructor(private modalService: NgbModal) { }

  modalRef: NgbModalRef;

  @ViewChild('ngbModal') ngbModal: NgbModal;

  @Input() title: string;
  @Input() showCloseButton: boolean;
  @Input() isAutoClose: boolean = true;
  @Input() centered: boolean = true;
  @Input() scrollable: boolean = false;
  @Input() size: string = '';
  @Input() modalDialogClass: string = '';
  @Input() windowClass: string = '';

  @Output() closed = new EventEmitter();
  @Output() submit = new EventEmitter();

  headerPl: AgModalHeader;
  contentPl: AgModalContent;
  footerPl: AgModalFooter;
  titlePl: AgModalTitle;

  @ContentChildren(AgModalHeader, { descendants: false }) headerPls: QueryList<AgModalHeader>;
  @ContentChildren(AgModalContent, { descendants: false }) contentPls: QueryList<AgModalContent>;
  @ContentChildren(AgModalFooter, { descendants: false }) footerPls: QueryList<AgModalFooter>;
  @ContentChildren(AgModalTitle, { descendants: false }) titlePls: QueryList<AgModalTitle>;

  ngAfterViewInit(): void {
  }

  ngAfterContentChecked(): void {
    this.headerPl = this.headerPls.first;
    this.contentPl = this.contentPls.first;
    this.footerPl = this.footerPls.first;
    this.titlePl = this.titlePls.first;
  }

  open(): void {
    this.modalRef = this.modalService.open(this.ngbModal, {
      backdrop: this.isAutoClose ? true : 'static',
      keyboard: this.isAutoClose ? true : false,
      centered: this.centered,
      scrollable: this.scrollable,
      size: this.size,
      modalDialogClass: this.modalDialogClass,
      windowClass: this.windowClass
    });

    this.modalRef.closed.subscribe(() => {
      this.closed.emit();
    });

    this.modalRef.dismissed.subscribe(() => {
      this.closed.emit();
    });
  }

  close(result?: any): void {
    this.modalRef.close(result);
  }

  dismiss(result?: any): void {
    this.modalRef.dismiss(result);
  }

  doSubmit(): void {
    this.dismiss();
    this.submit.emit();
  }

}
