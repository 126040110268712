import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConsts } from "src/app/shared/AppConsts";
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private httpClient: HttpClient
  ) { }

  
  GetByUserId(UserId: string | undefined): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${environment.ApiSettings.ClientPortalApi.AuthToken}`);

    return this.httpClient.get<any>(`${environment.ApiSettings.ClientPortalApi.BaseUrl}v1.0/User/GetByUserId/` + UserId, {headers:headers});

  }

  GetUsersByCientId(ClientId: number | undefined): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${environment.ApiSettings.ClientPortalApi.AuthToken}`);
    return this.httpClient.get<any>(`${environment.ApiSettings.ClientPortalApi.BaseUrl}UserAccountSetting/${ClientId}/users`, { headers: headers });
  }
  UpdateAccountInfo(updateInfo: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${environment.ApiSettings.ClientPortalApi.AuthToken}`);
    return this.httpClient.post<any>(`${environment.ApiSettings.ClientPortalApi.BaseUrl}v1/User/UpdateAccountInfo/`, updateInfo, { headers: headers });
  }
  ResetUserMFAByUserId(userId: string | undefined): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${environment.ApiSettings.ClientPortalApi.AuthToken}`);
    return this.httpClient.post<any>(`${environment.ApiSettings.ClientPortalApi.BaseUrl}v1.0/User/ResetUserMFAByUserId/` + userId , null , { headers: headers });
  }
  GetPMEmailAddressesByClientId(clientId: string | undefined): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${environment.ApiSettings.ClientPortalApi.Token}`);
    return this.httpClient.get<any>(`${environment.igniteBaseUrl}/alliantOneProjectAllAllocationByClientId/` + clientId, { headers: headers });
  }
  UpdateLoginActivity(userAccountSettingId: number | undefined): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${environment.ApiSettings.ClientPortalApi.AuthToken}`);
    var requestBody = {
      userAccountSettingId: userAccountSettingId,
      attemptSuccessful: true,
      LoginTime: null,
      LogoutTime: (new Date()).toISOString()
    }
    var d = this.httpClient.post<any>(`${environment.ApiSettings.ClientPortalApi.BaseUrl}LoginActivity/SaveLoginActivity`, requestBody, { headers: headers });
    return d;
  }
}
