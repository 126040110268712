<div id="windowContainer">
  <kendo-window
    class="window"
    [ngClass]="{'d-none' : !windowState.isVisible}"
    [resizable]="false"
    [draggable]="false"
    [width]="windowWidth"
    [height]="windowHeight"
    [top]="windowTop"
    [left]="windowLeft"
  >
    <kendo-window-titlebar class="titlebar" [ngClass]="windowTitlebarClass">
      <div class="window-title-container">
        <div class="window-title-icon">
          <fa-icon [icon]="windowTitleIcon"></fa-icon>
        </div>
        <span>{{windowTitleText}}</span>
        <span *ngIf="windowTitlebarState == WindowTitlebarState.Uploading">{{successfulUploadCount}} / {{activeUploadCount}}</span>
        <div *ngIf="windowTitlebarState == WindowTitlebarState.Uploading" class="spinner-border text-light mx-2" style="width: 18px; height: 18px;"></div>
      </div>
      <button *ngIf="windowState.size == UploadWindowSize.Maximized" class="window-action-btn" (click)="setUploadWindowSize(UploadWindowSize.Minimized)"><fa-icon [icon]="iconMinimize"></fa-icon></button>
      <button *ngIf="windowState.size == UploadWindowSize.Minimized" class="window-action-btn" (click)="setUploadWindowSize(UploadWindowSize.Maximized)"><fa-icon [icon]="iconMaximize"></fa-icon></button>
      <button class="window-action-btn" (click)="setUploadWindowVisible(false)"><fa-icon [icon]="iconXmark"></fa-icon></button>
    </kendo-window-titlebar>
    <div class="window-content-container">
      <ejs-uploader 
        #uploader  
        [autoUpload]='true'
        [directoryUpload]="uploaderIsDirectoryUpload"
        [asyncSettings]="uploaderAsyncSettings"
        [minFileSize]="1"
        [maxFileSize]="107374182400"
        [dropArea]="dropZoneElement"
        (selected)="onSelected($event)"
        (removing)="onRemoving($event)"
        (chunkUploading)="onChunkUploading($event)"
        (uploading)="onUploading($event)"
        (success)="onSuccess($event)"
        (failure)="onFailure($event)"
        (actionComplete)="onActionComplete($event)"
      ></ejs-uploader>
    </div>
  </kendo-window>
</div>