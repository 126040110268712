<ng-template #ngbModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"
            [hidden]="!showCloseButton"></button>
    </div>
    <div class="modal-body">
        <ng-template [ngTemplateOutlet]="contentPl.templateRef || null"></ng-template>
    </div>
    <div class="modal-footer" [hidden]="footerPl !== undefined">
        <button type="button" class="btn btn-primary" (click)="doSubmit()">Submit</button>
        <button type="button" class="btn btn-outline-primary" (click)="dismiss()">Cancel</button>
    </div>

    <div class="modal-footer" [hidden]="footerPl === undefined">
        <ng-template [ngTemplateOutlet]="footerPl.templateRef || null"></ng-template>
    </div>
</ng-template>