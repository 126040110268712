import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { catchError, switchMap, Observable, of, throwError } from "rxjs";
import { AppSessionService } from "../../services/session/app-session-service";
import { NotifyService } from "../services/notify/notify.service";
import { environment } from "src/environments/environment"

@Injectable()
export class AgHttpInterceptor implements HttpInterceptor {

  constructor(private appSessionService: AppSessionService,
    private notifyServie: NotifyService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.igniteBaseUrl) || req.url.includes(environment.ApiSettings.ClientPortalApi.BaseUrl)) {
     
      return next.handle(req)
        .pipe(catchError(error => {
          //if (error instanceof HttpErrorResponse && error.status === 401) {
          //  window.location.href = '/account/login';
          //}
          return this.handleErrorResponse(error);
        }), switchMap((event: any) => {
          return this.handleSuccessResponse(event);
        }));
    } else {
      var updatedRequest = this.normalizeRequestHeader(req);
      return next.handle(updatedRequest)
        .pipe(catchError(error => {
          //if (error instanceof HttpErrorResponse && error.status === 401) {
          //  window.location.href = '/account/login';
          //}
          return this.handleErrorResponse(error);
        }), switchMap((event: any) => {
          return this.handleSuccessResponse(event);
        }));
    }

  }

  protected handleErrorResponse(errorResponse: any): Observable<never> {
    if (errorResponse.error && errorResponse.error.statusCode
      && errorResponse.error.statusCode !== 200) {
      if (errorResponse.error.errors && errorResponse.error.errors.length > 0) {
        for (var i = 0; i < errorResponse.error.errors.length; i++) {
          this.notifyServie.error(errorResponse.error.errors[i]);
        }
      }
    }

    return throwError(() => errorResponse);
  }

  handleSuccessResponse(event: any) {
    if (event.headers && event.headers.get('_agresp')
      && event.body.statusCode === 200) {

      return of(event.clone({
        body: event.body.result
      }));
    }

    return of(event);
  }

  normalizeRequestHeader(request: HttpRequest<any>): HttpRequest<any> {
    var headers = new HttpHeaders();
    headers = this.addXRequestedWithHeader(headers);
    headers = this.addAuthorizationHeaders(headers);
    return request.clone({
      headers: headers
    });
  }

  getAdditionalHeaders() {
    const headers = {};
    return headers;
  }

  addXRequestedWithHeader(headers: HttpHeaders) {
    if (headers) {
      //headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    }
    return headers;
  }

  addAuthorizationHeaders(headers: HttpHeaders) {
    let authorizationHeaders = headers ? headers.getAll('Authorization') : null;
    if (!authorizationHeaders) {
      authorizationHeaders = [];
    }

    if (!authorizationHeaders.find((item) => item.indexOf('Bearer ') === 0)) {
      if (headers && this.appSessionService.token) {
        headers = headers.set('Authorization', 'Bearer ' + this.appSessionService.token);
      }
    }

    return headers;
  }
}
