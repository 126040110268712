import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, Point } from 'chart.js';

@Component({
  selector: 'ag-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss'],
})
export class ColumnChartComponent implements AfterViewInit {

  @ViewChild('columnChart') chartRef: ElementRef | any;

  chart: Chart | any;
  @Input() chartType: any = "bar";
  @Input() chartLegendConfig: any = {
    position: 'bottom',
  };
  @Input() chartScales: any;
  @Input() chartDataSet: any;
  @Input() chartLabels: any;


  constructor() {

  }

  ngAfterViewInit() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: this.chartType,
      data: {
        labels: this.chartLabels,
        datasets: this.chartDataSet,
      },
      options: {
        responsive: true,
         //maintainAspectRatio: false,
        scales: this.chartScales,

        plugins: {
          legend: this.chartLegendConfig,
        }
      }
    });
  }
}
