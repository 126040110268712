import { AfterContentChecked, Component, ContentChildren, Directive, Input, OnInit, QueryList, TemplateRef, ViewEncapsulation } from '@angular/core';

@Directive({ selector: 'ng-template[agCardContent]' })
export class AgCardContent {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[agLink]' })
export class AgLink {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'ag-card-template',
  exportAs: 'agCard',
  templateUrl: './card-template.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card-template.component.scss']
})
export class CardTemplate implements OnInit, AfterContentChecked {

  constructor() { }

  @Input() heading: string = "Heading";
  @Input() lastUpdatedDate: Date = new Date();
  @Input() showLastUpdatedDate: boolean = true;
  @Input() image: string = "";


  contentPl: AgCardContent;
  linkPl: AgLink;

  @ContentChildren(AgCardContent, { descendants: false }) contentPls: QueryList<AgCardContent>;
  @ContentChildren(AgLink, { descendants: false }) agLinkPls: QueryList<AgLink>;

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.contentPl = this.contentPls.first;
    this.linkPl = this.agLinkPls.first;
  }
}
