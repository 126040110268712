export enum UploadWindowSize {
  Minimized,
  Maximized
}

export enum DocumentRequestItemStatus {
  Requested = 1,
  Uploaded = 2,
  FollowUp = 3,
  AllDocsReceived = 4,
  ReceivedElsewhere = 5
}

export enum FileHierarchyNodeType {
  Folder,
  File
}