import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard  {
  constructor(
    private authorize: AuthorizeService, 
    private router: Router
  ) { }

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorize.isAuthenticated().pipe(
      tap(isAuthenticated => this.handleAuthorization(_next,isAuthenticated, state))
    );
  }

  private handleAuthorization(route: any, isAuthenticated: boolean, state: RouterStateSnapshot,) {
    if (!isAuthenticated) {
      state.url = state.url.includes("logged-out") ? '' : state.url;
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    } else {
      this.authorize.getUser().subscribe((user: any) => {
        // check if route is restricted by role
        if (route.data.roles) {
          // role not authorised so redirect to home page
          let roleAllowed = false;
          if (typeof (user.Role) == 'string')
            roleAllowed = (route.data.roles.indexOf(user.Role) > -1)
          else {
            user.Role.forEach((element : any) => {
              if (!roleAllowed && route.data.roles.indexOf(element) > -1)
                roleAllowed = true
            });
          }
        

          if (!roleAllowed) {
            const currentRoute = this.router.routerState;
            if (currentRoute.snapshot.url.includes('authentication') || currentRoute.snapshot.url == '') {
              if (user.Role == "INVOICEACCESS") this.router.navigate(['/invoices']);
              else this.router.navigate(['/documents']);

            }
            else {
              this.router.navigateByUrl(currentRoute.snapshot.url, { skipLocationChange: true });
            }

            
            return false;
          }
        
             
            
          
        }
        // authorised so return true
        return true;
      });
    }
  }
}

