import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { IBreadCrumb } from "./breadcrumb.interface";

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private baseHref = '/';

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {

    }

    private detectRouteChanges() {

    }

    private setupBreadcrumbs(activatedRouteSnapshot: ActivatedRouteSnapshot) {

    }

    public buildBreadCrmb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
        let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
        let title = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.title : '';
        let path = route.routeConfig && route.routeConfig.path ? route.routeConfig.path : ''; // route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

        const lastRoutePart = path ? path.split('/').pop() : null;
        const isDynamicRoute = lastRoutePart ? lastRoutePart.startsWith(':') : false;
        if (lastRoutePart && isDynamicRoute && !!route.snapshot) {
            const paramName = lastRoutePart?.split(':')[1];
            path = path?.replace(lastRoutePart, route.snapshot.params[paramName]);            
        }

        const nextUrl = path ? `${url}/${path}` : url;

        const breadcrumb: IBreadCrumb = {
            label: label,
            title: title,
            url: nextUrl
        };

        const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

        if (route.firstChild) {
            return this.buildBreadCrmb(route.firstChild, nextUrl, newBreadcrumbs);
        }

        return newBreadcrumbs;
    }
}