import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploadWindowComponent } from './components/document-upload-window/document-upload-window.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';

@NgModule({
  declarations: [
    DocumentUploadWindowComponent
  ],
  imports: [
    CommonModule,
    WindowModule,
    ButtonModule,
    SharedModule,
    UploaderModule
  ],
  exports: [
    DocumentUploadWindowComponent
  ]
})
export class DocumentUploadModule { }
