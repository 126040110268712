import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'phonenumber'
})
export class PhoneNumberPipe implements PipeTransform {
  
  transform(value: string | null): string | null {
    
    if(!value){
      return null;
    }
    
    let digits = value.replace(/\D/g, '');
    let phoneNumberMatch = digits.match(/^(\d*|)?(\d{3})(\d{3})(\d{4})$/);

    if (phoneNumberMatch) {
      let intlCode = phoneNumberMatch[1] ? `+${phoneNumberMatch[1]} ` : '';
      return `${intlCode}(${phoneNumberMatch[2]}) ${phoneNumberMatch[3]}-${phoneNumberMatch[4]}`;
    }

    return null;
  }
}