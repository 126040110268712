<ngb-accordion #ngbAcc="ngbAccordion" [ngClass]="{'accordian-transparent' : isTransparent}">
    <ngb-panel>
        <ng-template ngbPanelHeader>
            <div class="accordion-button ag-accordion-button custom-header justify-content-between">
                <button class="btn ag-accordion-button-title n-p d-inline-flex align-items-center" [ngClass]="{'font-bold':titleBold}" ngbPanelToggle><i
                        class="mr-2"></i> {{title}}</button>
                <div class="btn-edit" [ngClass]="{'btn-edit-disabled' : isEdit }" [hidden]="!editable"
                    (click)="showActions()">
                    <i class="fa-solid fa-pen-to-square"></i>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <ng-template [ngTemplateOutlet]="contentPl.templateRef || null"
                [ngTemplateOutletContext]="{editMode : isEdit}"></ng-template>
            <ng-template [ngTemplateOutlet]="footerPl.templateRef || null" *ngIf="isEdit"
                [ngTemplateOutletContext]="{editMode : isEdit}"></ng-template>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
