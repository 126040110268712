import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { firstValueFrom } from "rxjs";

export enum LogLevel {
  Debug = 0,
  Information = 1,
  Warning = 2,
  Error = 3
}

export class LogEntry {

  level : LogLevel = LogLevel.Debug;
  message : string = "";
  extraInfo : any[] = [];
  
  constructor(level : LogLevel, message : string, extraInfo : any[]) {
    this.level = level;
    this.message = message;
    this.extraInfo = extraInfo;
  }

  toString(): string {
    let result = `Message: ${this.message}`;

    if(this.extraInfo.length > 0){
      result += ` - Extra Info: ${this.extraInfoToString()}`;
    }

    return result;
  }

  extraInfoToString() : string {
    let result = this.extraInfo.map(item => JSON.stringify(item)).join(", ");
    return result;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private httpClient : HttpClient) { }

  async debug(message: string, ...optionalParams: any[]) {
    await this.writeToLog(LogLevel.Debug, message, optionalParams);
  }

  async information(message: string, ...optionalParams: any[]) {
    await this.writeToLog(LogLevel.Information, message, optionalParams);
  }

  async warning(message: string, ...optionalParams: any[]) {
    await this.writeToLog(LogLevel.Warning, message, optionalParams);
  }

  async error(message: string, ...optionalParams: any[]) {
    await this.writeToLog(LogLevel.Error, message, optionalParams);
  }

  private async writeToLog(logLevel: LogLevel, message: string, optionalParams: any[]) {
    let logEntry = new LogEntry(logLevel, message, optionalParams);
    
    let requestBody = {
      level: logEntry.level,
      message: logEntry.toString()
    }

    let headers = new HttpHeaders({ 'Authorization': `${environment.ApiSettings.ClientPortalApi.AuthToken}`});

    await firstValueFrom(this.httpClient.post(`${environment.ApiSettings.ClientPortalApi.BaseUrl}Log`, requestBody, { headers: headers }));
  }
}
