import { AfterContentChecked, Component, ContentChildren, Directive, EventEmitter, Inject, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from "@angular/core";
import { ColDef, GridApi, ColumnApi, GridReadyEvent, ColumnMovedEvent, ColumnResizedEvent, ColumnPinnedEvent, ColumnVisibleEvent, SelectionChangedEvent, IServerSideDatasource, RowGroupingDisplayType, StatusPanelDef, IServerSideGetRowsRequest, GetServerSideGroupLevelParamsParams, ServerSideGroupLevelParams, ColumnRowGroupChangedEvent, SortChangedEvent, ColumnState, ColumnStateParams, IColumnToolPanel } from "ag-grid-community";
import 'ag-grid-enterprise';
import { forkJoin, max, Observable } from "rxjs";
import { NotifyService } from "../../services/notify/notify.service";
import { AgModal } from "../modal/ag-modal.component";
import { IAgGridStore, ListViewSetting } from "./ag-grid.store";

export class AgGridViewItem {
  viewId?: number;
  name: string;
  columnState?: any | null;
  filterState?: any | null;
  default?: boolean = false;
  template?: boolean = false;
  serverId?: number;
  version?: number;
}

@Directive({ selector: 'ng-template[agGridViewOptions]' })
export class AgGridViewOptions {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'ag-grid',
  exportAs: 'agGrid',
  templateUrl: './ag-grid.component.html',
  host: {
    '[class]': '(overlayClass ? " " + overlayClass : "")'
  },
})
export class AgGridComponent implements OnInit, AfterContentChecked {

  constructor(
    @Inject('IAgGridStore') private agGridStore: IAgGridStore,
    private notifyService: NotifyService) { }

  @ViewChild('newViewListModal') newViewListModal: AgModal;
  @ViewChild('saveAsViewListModal') saveAsViewListModal: AgModal;
  @ViewChild('cloneViewListModal') cloneViewListModal: AgModal;
  @ViewChild('renameViewListModal') renameViewListModal: AgModal;
  @ViewChild('deleteViewListModal') deleteViewListModal: AgModal;

  viewOptionsPl: AgGridViewOptions;

  @ContentChildren(AgGridViewOptions, { descendants: false }) viewOptionsPls: QueryList<AgGridViewOptions>;

  public gridApi!: GridApi;
  public gridColumnApi!: ColumnApi;

  savedView: any = {
    viewName: null,
    viewId: null,
    columnState: null,
    filterState: null,
  };

  public defaultViewName: string = 'Standard General View';
  public selectedView: number = -1;
  public viewList: AgGridViewItem[] = [{
    viewId: -1,
    name: this.defaultViewName,
    default: true,
    columnState: null,
    filterState: null,
    template: true
  }];
  public viewListTemplates: AgGridViewItem[] = [];

  @Input() public listViewType: string = '';
  @Input() public gridStyles: any = {};
  @Input() public showGridOptions: boolean = false;
  @Input() public viewsEnabled: boolean = false;
  @Input() columnDefs: ColDef[] = [];
  @Input() columnSortDefs: any[] = [];
  @Input() pagination: boolean = false;
  @Input() pageSize: number = 100;
  @Input() rowModelType:  "clientSide" | "infinite" | "viewport" | "serverSide" | undefined = "serverSide";
  @Input() rowSelection: "single" | "multiple" | undefined = "multiple";
  @Input() suppressRowClickSelection : boolean = false;
  @Input() suppressCellSelection : boolean = false;
  @Input() floatingFilter: boolean = true;

  @Input() paginationPageSize: number = 0;
  @Input() cacheBlockSize = 100;

  @Input() gridData: any[]
  @Input() serverDataSource: IServerSideDatasource | any;
  @Input() public isServerSideInfiniteScroll: boolean | "" | null = null;
  @Input() rowGroupPanelShow : string = 'never';
  @Input() public groupDisplayType: RowGroupingDisplayType = 'groupRows';
  @Input() public alwaysMultiSort: boolean = true;
  @Input() public serverDataRequest: Observable<any>;

  @Output() public agGridReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() agGridSelection = new EventEmitter<boolean>();
  @Output() totalRecordCount: EventEmitter<number> = new EventEmitter<number>();

  overlayLoadingTemplate = `
    <i class="text-secondary">Loading...</i>
  `;

  overlayNoRowsTemplate = `
    <i class="text-secondary">No Rows to Display</i>
  `;

  private isListViewProfilesLoading = false;

  domLayout: 'normal' | 'autoHeight' | 'print' = 'normal';
  suppressAggFuncInHeader: true;
  
  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    hide: false,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    pinned: null,
    filterParams: {
      buttons: ['reset', 'apply'],
    },
  };

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
          suppressValues: true,
          suppressColumnMove: true,
          suppressSyncLayoutWithGrid: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ]
  };

  listViewModalInput: string = '';

  public statusBar = {
    statusPanels: [
      // {
      //   statusPanel: 'agTotalRowCountComponent',
      //   align: 'left',
      // }
    ]
  };

  saveSettingMessage: string = '';

  ngOnInit(): void {
    this.defaultColDef.floatingFilter = this.floatingFilter;
  }

  ngAfterContentChecked(): void {
    this.viewOptionsPl = this.viewOptionsPls.first;
  }

  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };

  getDefaultView(): any {
    return {
      id: -1,
      name: this.defaultViewName,
      default: true,
      columnState: null,
      filterState: null
    }
  }

  getSelectedView(): AgGridViewItem | undefined {
    return this.viewList.find(m => m.viewId === this.selectedView);
  }

  getMaxListViewId(): number {
    let maxId = Math.max(...this.viewList.map((obj: AgGridViewItem) => { return obj.viewId ?? 0; }));
    if (maxId <= 0) maxId = 1; else maxId++;
    return maxId;
  }

  loadListViewProfiles(): void {
    if (!this.viewsEnabled || this.listViewType === '' || this.listViewType.trim().length === 0) {
      this.viewsEnabled = false;
      return;
    }

    forkJoin([
      this.agGridStore.getTemplatesByKey(this.listViewType),
      this.agGridStore.getSettingsByKey(this.listViewType)
    ]).
      subscribe(([templates, settings]) => {
        if (templates) {
          templates.forEach((template: any) => {
            this.viewListTemplates.push({
              name: template.name,
              default: template.default,
              columnState: template.value ? JSON.parse(template.value) : null,
              filterState: template.filter ? JSON.parse(template.filter) : null,
              template: true,
              version: template.version
            });
          });
        }

        let defaultView = this.viewList.find(m => m.name === this.defaultViewName);
        if (defaultView == null || defaultView === undefined) {
          this.viewList = [...this.viewList, this.getDefaultView()];
        }

        // templates
        this.viewListTemplates.forEach((item: any) => {
          var viewIndex = this.viewList.findIndex(m => m.name === item.name);
          if (viewIndex >= 0) {
            this.viewList[viewIndex].columnState = item.columnState;
            this.viewList[viewIndex].filterState = item.filterState;
            this.viewList[viewIndex].default = item.default;
            this.viewList[viewIndex].version = item.version;
          }
          else {
            let templateView = {
              viewId: this.getMaxListViewId(),
              name: item.name,
              default: item.default,
              columnState: item.columnState,
              filterState: item.filterState,
              template: item.template,
              version: item.version
            };

            if (item.default) {
              let viewIndex = this.viewList.findIndex(m => m.default === true);
              if (viewIndex >= 0) {
                this.viewList[viewIndex].default = false;
              }
            }

            this.viewList = [...this.viewList, templateView];
            //this.syncListViewItem(templateView);
          }
        });

        // settings
        if (settings && settings.length > 0) {
          settings.forEach((setting: any) => {

            if (setting.default) {
              let viewIndex = this.viewList.findIndex(m => m.default === true);
              if (viewIndex >= 0) {
                this.viewList[viewIndex].default = false;
              }
            }

            let viewIndex = this.viewList.findIndex((obj: any) => obj.name === setting.name);
            if (viewIndex >= 0) {
              this.viewList[viewIndex].serverId = setting.id;
              this.viewList[viewIndex].default = setting.default;

              if (this.viewList[viewIndex].version !== null && this.viewList[viewIndex].version !== setting.version) {
                if (setting.value !== null && this.viewList[viewIndex].columnState !== null) {
                  var settingCols = JSON.parse(setting.value);
                  this.viewList[viewIndex].columnState.forEach((col: any) => {
                    if (!settingCols.find((m: any) => m.colId === col.colId)) {
                      settingCols.push(col);
                    }
                  });

                  this.viewList[viewIndex].columnState = settingCols;
                }
              }
              else {
                this.viewList[viewIndex].columnState = setting.value ? JSON.parse(setting.value) : null;
                this.viewList[viewIndex].filterState = setting.filter ? JSON.parse(setting.filter) : null;
                this.viewList[viewIndex].version = setting.version ?? null;
              }
              this.viewList = [...this.viewList];
            }
            else {
              this.viewList = [...this.viewList, {
                viewId: this.getMaxListViewId(),
                serverId: setting.id,
                name: setting.name,
                default: setting.default,
                columnState: setting.value ? JSON.parse(setting.value) : null,
                filterState: setting.filter ? JSON.parse(setting.filter) : null,
                template: false,
                version: setting.version
              }]
            }
          });
        }

        defaultView = this.viewList.find(m => m.default === true);
        this.setListViewItem(defaultView?.viewId ?? -1);
        if (defaultView) {
        }

        this.isListViewProfilesLoading = false;
        this.setGridReady();
      });
  }

  syncListViewItem(item: AgGridViewItem): void {
    if (item.columnState !== null) {
      item.columnState.forEach((state: ColumnStateParams) => {
      });
    }
  }

  refreshGrid() {
    this.gridApi.refreshServerSide({ route: [], purge: true });
  }

  getGridColumnState(): ColumnState[] {
    return this.gridColumnApi.getColumnState();
  }

  getGridFilterState(): any {
    return this.gridApi.getFilterModel();
  }

  //column state
  saveColumnState() {
    this.savedView.columnState = this.gridColumnApi.getColumnState();
  }

  resetColumnState() {
    this.gridColumnApi.resetColumnState();
  }

  restoreColumnState() {
    if (!this.savedView.columnState) {
      return;
    }
    this.gridColumnApi.applyColumnState({
      state: this.savedView.columnState,
      applyOrder: true,
    });
  }

  //filter state
  saveFilterState() {
    this.savedView.filterState = this.gridApi.getFilterModel();
  }

  restoreFilterState() {
    if (!this.savedView.filterState) {
      return;
    }
    this.gridApi.setFilterModel(this.savedView.filterState);

  }

  // Add event handlers
  onColumnMoved(event: ColumnMovedEvent) { this.saveColumnSettings(event);}
  onColumnResized(event: ColumnResizedEvent) { this.saveColumnSettings(event);}
  onColumnPinned(event: ColumnPinnedEvent) { this.saveColumnSettings(event);}
  onColumnVisible(event: ColumnVisibleEvent) { this.saveColumnSettings(event);}
  onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) { this.saveColumnSettings(event);}
  onSortChanged(event: SortChangedEvent) { this.saveColumnSettings(event);}

  onSelectionChanged(event: SelectionChangedEvent) {
    var selectedRows: any;
    selectedRows = this.gridApi.getSelectedRows();
    this.agGridSelection.emit(selectedRows);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.sortColumnsPanel();
    this.agGridReady.emit(params);
    this.setGridReady();
  }

  setGridReady() {
    if (this.serverDataSource && !this.isListViewProfilesLoading) {
      this.gridApi.setServerSideDatasource(this.serverDataSource);
    }
  }

  sortColumnsPanel(): void {
    const columnsToolPanel: IColumnToolPanel | any = this.gridApi.getToolPanelInstance('columns');

    if (!this.columnSortDefs || this.columnSortDefs.length === 0) {
      let columnSortDef: any[] = [];
      let gridColDefs: any[] | undefined = this.gridApi.getColumnDefs();

      if (gridColDefs !== undefined) {
        gridColDefs
          .map(item => item.colId)
          .sort().forEach(item => {
            columnSortDef.push({ colId: item });
          });

        this.columnSortDefs = columnSortDef;
        columnsToolPanel.setColumnLayout(columnSortDef);
      }
    }
    else {
      columnsToolPanel.setColumnLayout(this.columnSortDefs);
    }
  }

  prepareGridRequest(request: IServerSideGetRowsRequest | any): any {
    let groupByParams: string = '';
    let sortParams: [] | any = [];
    let whereParams: [] | any = [];
    let filterParams: [] | any = [];
    let selectParams: [] | any = [];

    // page no
    let pageNo = Math.ceil((request.startRow) / this.pageSize);
    if (!pageNo) {
      pageNo = 0;
    }

    // sort
    if (request.sortModel) {
      Object.keys(request.sortModel).forEach((key: any) => {
        let item = request.sortModel[key];
        sortParams.push({
          key: item.colId,
          value: item.sort
        });
      });
    }

    // group filter
    if (this.isDoingGrouping(request)) {
      groupByParams = request.rowGroupCols[request.groupKeys.length].id;
    }

    // where
    if (request.groupKeys.length > 0) {
      request.groupKeys.forEach((value: any, index: number) => {
        whereParams.push({
          key: request.rowGroupCols[index].field,
          value: value
        });
      });
    }

    // filter model
    Object.keys(request.filterModel).forEach((key: any) => {
      let item = request.filterModel[key];
      switch (item.filterType) {
        case 'text':
        case 'number':
        case 'date':
          filterParams.push({
            key: key,
            filterType: item.filterType,
            type: item.type,
            value: item.filter
          });
          break;

        case 'set':
          filterParams.push({
            key: key,
            filterType: item.filterType,
            value: item.values.join(",")
          });
          break;

        default:
          break;
      }
    });

    return {
      pageNo: pageNo,
      pageLimit: this.pageSize,
      groupBy: groupByParams,
      whereParams: whereParams,
      filterParams: filterParams,
      sortParams: sortParams,
      selectParams: this.getSelectParams(request)
    };
  }

  getSelectParams(request: IServerSideGetRowsRequest): any[] {
    if (this.isDoingGrouping(request)) {
      let rowGroupCol = request.rowGroupCols[request.groupKeys.length];
      let colsToSelect = [{
        key: rowGroupCol.id,
        aggFunc: ''
      }];

      request.valueCols.forEach((valueCol: any) => {
        if (valueCol.field !== colsToSelect[0].key) {
          colsToSelect.push({
            key: valueCol.field,
            aggFunc: valueCol.aggFunc
          })
        }
      });

      // filter duplicate records
      return colsToSelect.filter((item: any, i, arr: any[]) => {
        return arr.indexOf(arr.find((t: any) => t.key === item.key)) === i;
      });
    }

    return [];
  }

  isDoingGrouping(request: IServerSideGetRowsRequest): boolean {
    return request.rowGroupCols.length > request.groupKeys.length;
  }

  onResetViewClicked(): void {
  }

  viewListChange(item: any): void {
    this.notifyService.info('View is changed to ' + item.name);

    this.setListViewItem(item.viewId);

    // if (!item.columnState) {
    //   this.gridColumnApi.resetColumnState();
    //   item.columnState = this.gridColumnApi.getColumnState();
    // }

    // this.setGridState(item);

    // if (!item.serverId) {
    //   this.doSaveSetting(item)
    //     .subscribe((response: any) => {
    //       let viewIndex = this.viewList.findIndex(obj => obj.name === item.name);
    //       if (this.viewList[viewIndex].columnState === null) {
    //         this.viewList[viewIndex].columnState = item.columnState;
    //       }
    //       this.viewList[viewIndex].serverId = response.id;
    //       this.viewList = [...this.viewList];
    //     });
    // }
  }

  setGridState(item: AgGridViewItem): void {
    this.gridApi.setFilterModel(item.filterState);
    this.gridColumnApi.applyColumnState({
      state: item.columnState,
      applyOrder: true,
    });
  }

  setListViewItem(viewId: number): void {
    this.selectedView = viewId;

    let viewIndex = this.viewList.findIndex(ele => ele.viewId === this.selectedView);

    if (viewIndex < 0) return;

    if (!this.viewList[viewIndex].columnState) {
      this.gridColumnApi.resetColumnState();
      this.viewList[viewIndex].columnState = this.gridColumnApi.getColumnState();
    }

    this.setGridState(this.viewList[viewIndex]);

    if (!this.viewList[viewIndex].serverId) {
      this.doSaveSetting(this.viewList[viewIndex])
        .subscribe((response: any) => {
          this.viewList[viewIndex].serverId = response.id;
          this.viewList = [...this.viewList];
        });
    }
  }

  doResetView(): void {

    let viewIndex = this.viewList.findIndex(ele => ele.viewId === this.selectedView);

    if (this.selectedView === -1) {
      this.resetColumnState();
      this.viewList[viewIndex].columnState = this.getGridColumnState();
      this.viewList[viewIndex].filterState = null;
    }
    else {
      var templateView = this.viewListTemplates.find(item => item.name === this.viewList[viewIndex].name);
      if (templateView) {
        this.viewList[viewIndex].columnState = templateView.columnState;
        this.viewList[viewIndex].filterState = templateView.filterState;
      }
    }

    this.doSaveSetting(this.viewList[viewIndex])
      .subscribe((response) => {
        this.setGridState(this.viewList[viewIndex]);
        this.notifyService.info('View is sucessfully reset.');
      });
  }

  doSaveViewFilter(): void {
    let viewIndex = this.viewList.findIndex(ele => ele.viewId === this.selectedView);
    this.viewList[viewIndex].filterState = this.gridApi.getFilterModel();
    this.doSaveSetting(this.viewList[viewIndex])
      .subscribe((response) => {
        this.notifyService.info('Filters are saved for selected view: ' + this.viewList[viewIndex].name);
      });
  }

  doSaveAsViewFilter(): void {
    let view = this.viewList.find((obj: AgGridViewItem) => {
      return obj.viewId === this.selectedView;
    })

    if (view) {
      this.listViewModalInput = 'copy - ' + view.name;
      this.saveAsViewListModal.open();
    }
  }

  saveColumnSettings(params: any) {

    if (!this.viewsEnabled) return;

    let viewIndex = this.viewList.findIndex(ele => ele.viewId === this.selectedView);

    if (
      (
        (params.source === 'uiColumnDragged' || params.source === 'toolPanelUi' || params.source === 'contextMenu')
        &&
        ((params.type === 'columnResized' && params.finished) ||
          (params.type === 'columnMoved') ||
          (params.type === 'columnRowGroupChanged') ||
          (params.type === 'columnVisible') ||
          (params.type === 'columnPinned')

        )
      ) || (params.type === 'sortChanged')
    ) {
      this.sortColumnsPanel();

      this.viewList[viewIndex].columnState = this.gridColumnApi.getColumnState();

      if (this.selectedView === -1 || this.viewList[viewIndex]?.template) {

      }
      else {
        this.viewList[viewIndex].filterState = this.gridApi.getFilterModel();
      }

      this.saveSettingMessage = 'saving';
      this.doSaveSetting(this.viewList[viewIndex])
        .subscribe((response: any) => {
          this.saveSettingMessage = 'saved';

          setTimeout(() => {
            this.saveSettingMessage = '';
          }, 2000);
        });
    }
  }

  doSaveSetting(item: AgGridViewItem, callback?: any): Observable<any> {
    return this.agGridStore.updateListViewSetting({
      key: this.listViewType,
      userId: 1,
      name: item.name,
      value: item.columnState ? JSON.stringify(item.columnState) : null,
      filter: item.filterState ? JSON.stringify(item.filterState) : null,
      default: item.default,
      template: item.template,
      version: item.version
    });
  }

  isDefaultView(): Boolean {
    return this.selectedView == -1;
  }

  onNewViewListBtnClicked(): void {
    this.newViewListModal.open();
  }

  onCloneViewListBtnClicked(): void {
    let view = this.viewList.find((obj: AgGridViewItem) => {
      return obj.viewId === this.selectedView;
    })

    if (view) {
      this.listViewModalInput = 'clone - ' + view.name;
      this.cloneViewListModal.open();
    }
  }

  onRenameViewListBtnClicked(): void {
    let view = this.viewList.find((obj: AgGridViewItem) => {
      return obj.viewId === this.selectedView;
    });

    if (!view) return;

    this.listViewModalInput = view.name;
    this.renameViewListModal.open();
  }

  onDeleteViewListBtnClicked(): void {
    this.deleteViewListModal.open();
  }

  onSetDefaultViewListBtnClicked(): void {
    this.doSetDefaultViewList();
  }

  doCreateNewViewList(): void {
    let viewName = this.listViewModalInput.trim();

    if (viewName.length == 0) {
      this.notifyService.error('View name is required.');
      return;
    }

    const existView = this.viewList.find((obj: any) => {
      return obj.name.toLowerCase() === viewName.toLocaleLowerCase();
    })

    if (existView) {
      this.notifyService.error('View name already exist.');
      return;
    }

    let maxId = this.getMaxListViewId();

    this.gridColumnApi.resetColumnState();
    this.gridApi.setFilterModel({});

    const view: AgGridViewItem = {
      viewId: maxId,
      name: viewName,
      columnState: this.getGridColumnState()
    }

    this.doSaveSetting(view)
      .subscribe((response: any) => {
        this.viewList = [...this.viewList, view];

        this.selectedView = maxId;
        this.notifyService.success('View is successfully created.');
      });

    this.newViewListModal.close();

  }

  doCloneViewList(): void {
    let viewName = this.listViewModalInput.trim();

    if (viewName.length == 0) {
      this.notifyService.error('View name is required.');
      return;
    }

    let view = this.viewList.find((obj: any) => {
      return obj.name.toLowerCase() === viewName.toLocaleLowerCase();
    })

    if (view) {
      this.notifyService.error('View name already exist.');
      return;
    }

    let maxId = this.getMaxListViewId();

    const newView: AgGridViewItem = {
      viewId: maxId,
      name: viewName,
      columnState: this.getGridColumnState(),
      filterState: this.getGridFilterState(),
      template: false,
      default: false
    };

    this.doSaveSetting(newView)
      .subscribe((response: any) => {
        this.viewList = [...this.viewList, newView];
        this.selectedView = maxId;
        this.cloneViewListModal.close();
        this.notifyService.success('View is successfully created.');
      });
  }

  doSaveAsViewList(): void {
    let viewName = this.listViewModalInput.trim();

    if (viewName.length == 0) {
      this.notifyService.error('View name is required.');
      return;
    }

    let view = this.viewList.find((obj: any) => {
      return obj.name.toLowerCase() === viewName.toLocaleLowerCase();
    })

    if (view) {
      this.notifyService.error('View name already exist.');
      return;
    }

    let maxId = this.getMaxListViewId();

    const newView: AgGridViewItem = {
      viewId: maxId,
      name: viewName,
      columnState: this.getGridColumnState(),
      filterState: this.getGridFilterState(),
      template: false,
      default: false
    };

    this.doSaveSetting(newView)
      .subscribe((response: any) => {
        this.viewList = [...this.viewList, newView];
        this.selectedView = maxId;
        this.saveAsViewListModal.close();
        this.notifyService.success('View is successfully created.');
      });
  }

  doRenameViewList(): void {
    let viewName = this.listViewModalInput.trim();

    if (viewName.length == 0) {
      this.notifyService.error('View name is required.');
      return;
    }

    let view = this.viewList.find((obj: AgGridViewItem) => {
      return obj.name.toLowerCase() === viewName.toLocaleLowerCase() && obj.viewId !== this.selectedView;
    });

    if (view) {
      this.notifyService.error('View name already exist.');
      return;
    }

    let viewList = this.viewList.filter((obj: AgGridViewItem) => obj.viewId !== this.selectedView);

    this.agGridStore.renameListViewSetting({
      id: this.getSelectedView()?.serverId,
      key: this.listViewType,
      userId: 1,
      name: viewName
    }).subscribe((response: any) => {
      this.viewList = [...viewList, {
        viewId: this.selectedView,
        name: viewName,
        columnState: this.getGridColumnState(),
        filterState: this.getGridFilterState()
      }];

      this.notifyService.success('View is successfully updated.');
      this.renameViewListModal.close();
    });
  }

  doDeleteViewList(): void {
    let view = this.viewList.find((obj: AgGridViewItem) => {
      return obj.viewId === this.selectedView;
    });

    this.deleteViewListModal.close();

    if (!view) {
      return;
    }

    this.agGridStore.deleteListViewSetting({
      key: this.listViewType,
      userId: 1,
      name: view.name
    }).subscribe((response: any) => {
      let viewList = this.viewList.filter((obj: AgGridViewItem) => obj.viewId !== this.selectedView);
      this.viewList = [...viewList];
      this.setListViewItem(-1);
      this.notifyService.success('View is deleted successfully.');
    });
  }

  doSetDefaultViewList(): void {
    const view = this.viewList.find((obj: AgGridViewItem) => {
      return obj.viewId === this.selectedView;
    });

    if (!view) {
      return;
    }

    this.agGridStore.setDefaultListViewSetting({
      key: this.listViewType,
      userId: 1,
      name: view.name,
      id: view.serverId
    }).subscribe((response: any) => {
      let viewIndex = this.viewList.findIndex((obj: AgGridViewItem) => obj.viewId !== this.selectedView);
      this.viewList[viewIndex].default = true;
      this.viewList = [...this.viewList];
      this.notifyService.success('View is successfully updated.');
    });
  }

  viewListModalClosed(): void {
    this.resetViewListModal();
  }

  resetViewListModal(): void {
    this.listViewModalInput = '';
  }
}
