import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAgGridStore, ListViewSetting, ListViewTemplate } from "src/app/lib/ui/ag-grid/ag-grid.store";
import { AppConsts } from "src/app/shared/AppConsts";
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListViewService implements IAgGridStore {
  constructor(
    private httpClient: HttpClient
  ) { }

  getSettingsByKey(key: string): Observable<ListViewSetting[]> {
    return this.httpClient.get<ListViewSetting[]>(`${AppConsts.apiBaseUrl}/api/v1/listview/getlistviewsettingsbyuserid/` + 1 + '/' + key);
  }

  getTemplatesByKey(key: string): Observable<ListViewTemplate[]> {
    return this.httpClient.get<ListViewTemplate[]>(`${AppConsts.apiBaseUrl}/api/v1/listview/getlistviewtemplatesbykey/` + key);
  }

  updateListViewSetting(data: ListViewSetting): Observable<ListViewSetting> {
    return this.httpClient.post<ListViewSetting>(`${AppConsts.apiBaseUrl}/api/v1/listview/updatelistviewsetting`, data);
  }

  renameListViewSetting(data: ListViewSetting): Observable<boolean> {
    return this.httpClient.post<boolean>(`${AppConsts.apiBaseUrl}/api/v1/listview/renamelistviewsetting`, data);
  }

  deleteListViewSetting(data: ListViewSetting): Observable<boolean> {
    return this.httpClient.post<boolean>(`${AppConsts.apiBaseUrl}/api/v1/listview/deletelistviewsetting`, data);
  }

  setDefaultListViewSetting(data: ListViewSetting): Observable<boolean> {
    return this.httpClient.post<boolean>(`${AppConsts.apiBaseUrl}/api/v1/listview/setdefaultlistviewsetting`, data);
  }
}

export class UpdateListViewSettingDto {
  userId: number;
  key: string;
  name: string;
  value?: string;
  filter?: string;
  default?: boolean;
  template?: boolean;
  version?: number;
}
