import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridsterModule } from 'angular-gridster2';
import { UIModule } from "../lib/ui/ui.module";
import { LoadingSpinnerComponent } from './Components/loading-spinner/loading-spinner.component';
import { ListViewService } from "@services/listviews/listview.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PhoneNumberPipe } from "./pipes/phone-number.pipe";
import { PadLeftPipe } from './pipes/pad-left.pipe';
import { NoDataPlaceholderComponent } from './Components/no-data-placeholder/no-data-placeholder.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    PhoneNumberPipe,
    PhoneNumberPipe,
    PadLeftPipe,
    NoDataPlaceholderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AgGridModule,
    NgbModule,
    UIModule,
    NgSelectModule,
    IntlModule,
    DateInputsModule,
    GridsterModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AgGridModule,
    NgbModule,
    UIModule,
    NgSelectModule,
    GridsterModule,
    LoadingSpinnerComponent,
    PhoneNumberPipe,
    PhoneNumberPipe,
    PadLeftPipe,
    NoDataPlaceholderComponent,
    FontAwesomeModule, 
    ReactiveFormsModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: 'IAgGridStore',
          useClass: ListViewService
        }
      ]
    }
  }
}
